<template>
  <div class="accept-container">
    <transition name="fade" mode="out-in">
      <div class="loader-container" v-if="!isAccepted && !isCancelledOrder">
        <loader />
        <h1>
          {{ T("Web.Generic.Ordering.AcceptingOrder", "Accepting order") }}...
        </h1>
      </div>
      <div v-else-if="isAccepted" class="d-flex flex-column">
        <div class="accepted-container text-primary">
          <feather-icon icon="CheckIcon" class="mr-1" size="80" />
          <h1 class="text-primary">
            {{ T("Web.Generic.Ordering.OrderAccepted", "Order accepted") }}!
          </h1>
        </div>
        <b-button
          class="mt-1"
          :to="{
            name: 'supplier-contacts.orders.list',
            params: { contactId: $route.params.contactId },
          }"
          >{{ T("Web.Generic.Ordering.ViewAllOrders", "View all orders")
          }}<feather-icon icon="ChevronRightIcon"
        /></b-button>
      </div>
      <div v-else-if="isCancelledOrder">
        <div class="accepted-container text-danger">
          <feather-icon icon="MinusCircleIcon" class="mr-1" size="80" />
          <h1 class="text-danger">
            {{
              T(
                "Web.Generic.Ordering.OrderCancledByCustomer",
                "Order canceled by customer"
              )
            }}
          </h1>
        </div>
        <div class="text-center">

        </div>
        <b-button
          class="mt-1"
          :to="{
            name: 'supplier-contacts.orders.list',
            params: { contactId: $route.params.contactId },
          }"
          >{{ T("Web.Generic.Ordering.ViewAllOrders", "View all orders")
          }}<feather-icon icon="ChevronRightIcon"
        /></b-button>
      </div>
    </transition>
  </div>
</template>

<script>
import Loader from "@/app/components/layout/Loader.vue";
import SupplierContactsOrdersService from "../../services/supplier-contacts-orders.service";
import { BButton } from "bootstrap-vue";
export default {
  components: { Loader, BButton },
  data() {
    return {
      isCancelledOrder: false,
      isAccepted: false,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
  async mounted() {
    try {
      const service = new SupplierContactsOrdersService(
        this.$route.params.contactId
      );
      var response = await service.getOderById(this.orderId);
      if (response.data.data.status == "Canceled") {
        this.isCancelledOrder = true;
      } else {
        await service.acceptOrderById(this.orderId);
        this.isAccepted = true;
      }
    } catch {
      alert("An unknown error happend!");
    }
  },
};
</script>

<style lang="scss" scoped>
.accept-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-container,
  .accepted-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>